import React from "react";
import { graphql } from "gatsby";
import ReactCompareImage from "react-compare-image";
import ModalVideo from "react-modal-video";

import Hero from "../../components/try-now/hero";
import Layout from "../../components/shared/layout-light";
import Img from "gatsby-image";
import FeaturedInLogos from "../../components/owner-since/featured-in";
import TryToday from "../../components/try-now/try-today";
import sliderHandleSvg from "../../images/try-now/slider-handle.svg";

import Section from "../../components/try-now/section";
import Perks from "../../components/try-now/perks";
import VideoCta from "../../components/try-now/video-cta";
import WistiaVideoPopoverLink from "../../components/owner-since/wistia-video-popover-link";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "try-now/hero.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1240) {
          ...GatsbyImageSharpSizes
        }
      }
    }

    mods: file(relativePath: { eq: "try-now/mods.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1150, maxHeight: 558) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    spill: file(relativePath: { eq: "try-now/spill.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1150, maxHeight: 558) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shell: file(relativePath: { eq: "try-now/shell.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1150, maxHeight: 558) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sustainable: file(relativePath: { eq: "try-now/outer6323-v1_.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1750, maxHeight: 1165) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    teak: file(relativePath: { eq: "try-now/teak_3-piece_gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2004, maxHeight: 1116) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wicker: file(relativePath: { eq: "try-now/wicker_3-piece_gray-alt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2004, maxHeight: 1116) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const TryNowPage = function ({ data }) {
  const [videoId, setVideoId] = React.useState(null);

  return (
    <Layout homeUrl="/try-now">
      <Hero data={data} />

      <Perks />

      <div className="content-container mt-10 sm:mt-14 lg:mt-36 lg:pt-2">
        <Section
          description="Experience the Outer Sofa  risk-free for 14 days from the comfort of your own home."
          title="Try the Tesla of Outdoor furniture"
        >
          <VideoCta
            onPlay={() => setVideoId("8CXMaviCW6M")}
            image={data.shell.childImageSharp.fluid}
            text="Explore the OuterShell® cover"
          />
        </Section>

        <Section
          description="Outer’s proprietary eco-friendly fabrics are stain-resistant, mold-resistant, fade resistant, and can even be cleaned in your washing machine."
          title="Life-Proof Proprietary Fabrics"
        >
          <WistiaVideoPopoverLink videoId="d8thwgcicg">
            <VideoCta
              image={data.spill.childImageSharp.fluid}
              text="See the fabrics in action"
              isWistiaVideo
            />
          </WistiaVideoPopoverLink>
        </Section>

        <Section
          description="All-Weather Wicker and Grade A Teak frames provide unparalleled durability and style. Plus, they’re eco-friendly."
          title="Choose from two ultra-premium  all-weather frame materials"
        >
          <ReactCompareImage
            handle={<img src={sliderHandleSvg} />}
            leftImage={data.wicker.childImageSharp.fluid.src}
            rightImage={data.teak.childImageSharp.fluid.src}
          />
          <div className="flex justify-between mt-3 sm:mt-20 sm:-mb-10">
            <span className="text-sm sm:text-2xl text-blue-900">
              All-Weather Wicker
            </span>
            <span className="text-sm sm:text-2xl text-blue-900">
              Grade A Teak <br />
              <span className="text-white-900 bg-gray-500 px-2 py-1 rounded-full w-auto text-xs sm:text-sm mx-auto">
                NEW
              </span>
            </span>
          </div>
        </Section>

        <Section
          description="Arrange and re-arrange your modular pieces for different configuration options"
          title="Modular pieces conveniently fit your space and style"
        >
          <Img fluid={data.mods.childImageSharp.fluid} />
        </Section>

        <Section
          description="We’ve crafted every Outer Sofa using premium eco-friendly materials to ensure a better future for our planet"
          title="Sustainably crafted for a better tomorrow"
        >
          <VideoCta
            onPlay={() => setVideoId("wRHuqdA39_s")}
            image={data.sustainable.childImageSharp.fluid}
            text="Proud members of 1% for the planet"
          />
        </Section>

        <FeaturedInLogos />
      </div>

      <TryToday />

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={videoId}
        videoId={videoId}
        onClose={() => setVideoId(null)}
      />
    </Layout>
  );
};

export default TryNowPage;
