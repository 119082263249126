import Button from "../owner-since/button";
import React from "react";

export default function Section({ title, description, children }) {
  return (
    <div className="text-blue-900 text-center mb-20 sm:mb-36 sm:pb-3">
      <h2 className="tracking-wide sm:tracking-normal text-2xl md:mx-auto md:max-w-xl lg:max-w-5xl lg:px-14 lg:text-6xl">
        {title}
      </h2>
      <p className="mt-3 text-sm sm:text-2xl sm:mt-10 sm:tracking-wide lg:max-w-2xl lg:mx-auto">
        {description}
      </p>

      <div className="text-center mt-7 mb-10 pb-0 sm:mb-16 sm:pb-2 sm:mt-8 lg:mt-10 lg:pt-1 lg:mb-12">
        <Button to="/try-now/contact">Try Now For $0</Button>
      </div>
      {children}
    </div>
  );
}
