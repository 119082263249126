import shippingSvg from "../../images/try-now/fast-shipping.svg";
import returnsSvg from "../../images/try-now/returns.svg";
import warrantySvg from "../../images/try-now/warranty.svg";
import React from "react";

export default function Perks() {
  return (
    <div className="content-container-max mt-5 sm:mt-10 lg:mt-14 lg:pt-0">
      <ul className="sm:flex text-center">
        <li className="mx-auto my-4 text-sm sm:my-0 lg:text-2xl text-blue-900 w-1/3">
          <img className="mx-auto mb-2" src={shippingSvg} /> FREE curbside
          shipping
        </li>
        <li className="mx-auto my-4 text-sm sm:my-0 lg:text-2xl text-blue-900 w-1/3">
          <img className="mx-auto mb-2" src={returnsSvg} /> FREE and easy
          returns
        </li>
        <li className="mx-auto my-4 text-sm sm:my-0 lg:text-2xl text-blue-900 w-1/3">
          <img className="mx-auto mb-2" src={warrantySvg} /> Best-in-class
          warranties
        </li>
      </ul>
    </div>
  );
}
