import Img from "gatsby-image";
import playSvg from "../../images/owner-since/play.svg";
import React from "react";

export const HOVER_TRANSITION =
  "hover:opacity-75 focus:opacity-50 transition duration-300";

export default function VideoCta({ onPlay, image, text, isWistiaVideo }) {
  return (
    <div className="relative">
      <div
        className={`cursor-pointer ${HOVER_TRANSITION}`}
        onClick={onPlay || undefined}
      >
        {isWistiaVideo ? (
          <img alt={text} src={image.src} />
        ) : (
          <Img alt={text} fluid={image} />
        )}

        <button
          className={`items-center flex text-blue-900 mx-auto text-sm mt-5 sm:mt-10 lg:text-2xl`}
        >
          <img className="mr-2 w-3 md:w-auto" src={playSvg} /> {text}
        </button>
      </div>
    </div>
  );
}
