import React from "react";

import Button from "../owner-since/button";
import plantSvg from "../../images/owner-since/plant.svg";

export default function TryToday() {
  return (
    <div className="content-container-max sm:mt-10 lg:mt-0">
      <div className="bg-blue-900 py-10 bg-no-repeat bg-right-bottom mt-14 lg:py-32 relative">
        <div className="content-container text-white-900 sm:flex relative z-10">
          <div className="px-2 sm:px-0 sm:w-1/2 sm:flex sm:items-center">
            <div>
              <h2 className="text-xl mb-5 sm:mb-12 sm:text-3xl lg:text-4xl xl:text-6xl">
                Try your Outer Sofa today
              </h2>
              <div className="hidden sm:block">
                <Button to="/try-now/contact" light>
                  Try Outer For $0
                </Button>
              </div>
            </div>
          </div>

          <div className="px-2 sm:px-0 sm:w-1/2 sm:flex sm:items-center xl:justify-end">
            <ul className="mt-5 sm:text-xl md:text-2xl xl:text-4xl lg:mt-0">
              <li className="mb-1 sm:mb-6 md:mb-12">- Patented protection</li>
              <li className="mb-1 sm:mb-6 md:mb-12">
                - Ultra-premium materials
              </li>
              <li>- Sustainably crafted</li>
            </ul>
          </div>

          <div className="px-2 mt-7 sm:hidden">
            <Button to="/try-now/contact" fluid light>
              Try Outer For $0
            </Button>
          </div>
        </div>
        <img
          className="absolute right-0 bottom-0 w-28 md:w-auto z-0"
          src={plantSvg}
        />
      </div>
    </div>
  );
}
