import Img from "gatsby-image";
import React from "react";

import Button from "../owner-since/button";

export default function Hero({ data }) {
  return (
    <div className="relative text-center text-blue-900 lg:text-white-900">
      <Img
        className="darken-image"
        fluid={data.hero.childImageSharp.sizes}
        alt="Try an Outer Sofa at home"
      />

      <div className="mt-7 lg:mt-0 lg:absolute lg:-inset-0 lg:flex lg:flex-col">
        <div className="content-container">
          <h1 className="text-4xl lg:text-5xl xl:text-7xl lg:mt-12 2xl:mt-40">
            Try an Outer Sofa at home <br className="hidden sm:block" />
            for $0
          </h1>
          <div className="lg:w-7/12 lg:mx-auto">
            <p className="text-lg mt-2 mb-6 sm:mb-10 lg:mt-7 lg:leading-normal lg:tracking-wide lg:text-2xl">
              Experience the Outer Sofa risk-free for 14 days from the comfort
              of your own home.
            </p>
          </div>
          <Button to="/try-now/contact">Try Now for $0</Button>
        </div>
      </div>
    </div>
  );
}
